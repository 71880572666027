/* Scoped styles for ImageGallery component */

.container {
  width: 100%;
  height: 700px; 
  overflow: hidden;
  position: relative;
}

.slides {
  position: relative;
  width: 100%;
  height: 100%;
  list-style: none;
  margin: 0;
  padding: 0;
}

.slide {
  position: absolute;
  width: 100%;
  height: 100%;
  transition: opacity 1s ease-in-out;
  opacity: 0;
  background-size: contain; /* Ensure the entire image is visible */
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Avoid tiling the image */
}

.slide.active {
  opacity: 1;
}

.title {
  position: absolute;
  top: 15%; /* Adjust this value to control how far down the title appears on the image */
  left: 50%;
  z-index: 2; /* Ensures the title appears above the image */
  transform: translateX(-50%);
  font-family: 'Reem Kufi', sans-serif;
  font-size: 2.8em;
  color: black;
  white-space: nowrap; /* Prevents the title from wrapping to a new line */
  padding: 10px 20px; /* Optional: Adds padding around the text for better visibility */
}

.titleText {
  display: block;
  transform: translateY(0); /* Keep the text in place */
  text-align: center;
  transition: transform 1s ease-in-out;
}

.slide.active .title .titleText {
  transform: translate(0);
  transition-delay: 0.3s;
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures the image covers the container */
  background-color: black;
}

.slideSelect {
  position: absolute;
  bottom: 20px;
  left: 20px;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-family: 'Reem Kufi', sans-serif;
  font-size: 1.5em;
  font-weight: lighter;
  color: white;
  list-style: none;
  margin: 0;
  padding: 0;
}

.slideSelect li {
  position: relative;
  cursor: pointer;
  margin: 0 5px;
}

.slideSelect li.prev:hover {
  transform: translateX(-2px);
}

.slideSelect li.next:hover {
  transform: translateX(2px);
}

.selector {
  height: 14px;
  width: 14px;
  border: 2px solid white;
  background-color: transparent;
  transition: background-color 0.5s ease-in-out;
}

.selector.current {
  background-color: white;
}
